import React from "react";
import Navbar, {Nav} from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";


const menuContent = [
    {
        /*name: "Accueil",
        dropdownMenu: [
            {name: "Main Demo", to: "/"},
            {name: "One Page 2", to: "/demo-2"},
            {name: "One Page 3", to: "/demo-3"},
        ]*/
        name: "Accueil", to: "/",
    },
    {
        name: "Portfolio",
        dropdownMenu: [
            {
                /*name: "Diapositive",
                dropdownMenu: [
                    {name: "Plein écran", to: "/slider"},
                    //{name: "Padding Screen", to: "/slider-2"},
                    //{name: "Half Screen", to: "/slider-3"}
                ]*/
                name: "Diapositive", to: "/slider",
            }, {
                /*name: "Colonne",
                dropdownMenu: [
                    {name: "2 Colonnes", to: "/portfolio"},
                    //{name: "Grid 3 Columns", to: "/portfolio-2"},
                ]*/
                name: "Colonne", to: "/portfolio",
            },

        ]
    },
    {
        name: "Projets",
        dropdownMenu: [
            {
                name: "Projets 01 - 05",
                dropdownMenu: [
                    {name: "Elephanti Memoria", to: "/portfolio/elephanti-memoria"},
                    {name: "Ciné Défis", to: '/portfolio/cine-defis'},
                    {name: "Coze", to: '/portfolio/coze'},
                    //{name: "Toast 2019 Reel", to: '/portfolio/toast-2019-reel'},
                    //{name: "What If Gold", to: '/portfolio/what-if-gold'},
                ]
            },/*{
                name: "Works 05 - 09",
                dropdownMenu: [
                    {name: "Nile - Kabutha", to: "/portfolio/nile-kabutha"},
                    {name: "Sleep Walker", to: '/portfolio/sleep-walker'},
                    {name: "Novara Conic", to: '/portfolio/novara-conic'},
                    {name: "Bastian Bux", to: '/portfolio/bastian-bux'},

                ]
            },*/
        ]
    },
    {name: "À propos", to: "/a-propos"},
    {name: "Contact", to: "/contact"},
];

const DroowMenu = ({hamburger}) => {

    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (<Nav.Dropdown name={items.name}
                                  key={$key}>{items.dropdownMenu.map(item => getSubMenu(item))}</Nav.Dropdown>);
        }
        return (
            <Nav.Link to={items.to} key={$key}>{items.name}</Nav.Link>
        );
    }


    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={"/"}>
                <Brand width="80px" height="26px"/>
            </Navbar.Brand>

            <Navbar.Collapse cover="Menu">
                <Nav>
                    {menuContent.map(item => getSubMenu(item))}
                </Nav>
                <MenuContent className="section-margin"/>
            </Navbar.Collapse>
        </Navbar>
    );

}

DroowMenu.defaultProps = {
    hamburger: false
}


export default DroowMenu;