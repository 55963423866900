import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {/*{link: "#0", name: "Dribbble."},
    {link: "#0", name: "Behance."},*/},
        {link: "https://www.linkedin.com/in/alexis-metton-05666a244/", name: "Linkedin."},
        {link: "https://github.com/AlexisMetton", name: "GitHub."}
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Adresse</h5>
                    <p>
                        58 000 Nevers<br/> France
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+33785601593" data-hover-text="+33 7 85 60 15 93">
                        +33 7 85 60 15 93
                        </a>
                    </p>
                    <p className="links over-hidden">
                        {/*<a className="link-hover" href="mailto:info@dsngrid.com"
                           data-hover-text="alexis.metton@gmail.com">alexis.metton@gmail.com</a>
    */}</p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Suivez-moi</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;